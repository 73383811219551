import React from "react";

const AboutFormaxPay = () => {
  return (
    <section class="relative md:py-4 py-16 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 pb-2 text-center">
          <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            About Us
          </h3>
        </div>

        <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-2 gap-[30px]">
          <div class="lg:col-span-5">
            <img src="images/about-us-2.jpg" alt="" />
          </div>
          <div class="lg:col-span-7  md:mt-0">
            <div class="lg:ml-10">
              <p class="max-w-xl" style={{ textAlign: "justify" }}>
                Formax Pay is a financial technology platform. Since 2017, we
                are working towards building a robust platform to offer seamless
                financial services. We at Formax Pay empower shop owners by
                providing various financial, banking, payment, collection,
                insurance, tours, travel, and e-governance services through our
                robust platform.
              </p>

              {/* <p class="max-w-xl mt-2">
                We at Formax Pay, our focus is on making financial services more
                simpler, safer & smarter for our customers by simplifying
                finance with latest technology.
              </p> */}

              {/* 
              <ul class="list-none text-slate-400 mt-4">
                <li class="mb-1 flex">
                  <i class="uil uil-check-circle text-indigo-600 text-xl mr-2"></i>{" "}
                  Digital Marketing Solutions for Tomorrow
                </li>
                <li class="mb-1 flex">
                  <i class="uil uil-check-circle text-indigo-600 text-xl mr-2"></i>{" "}
                  Our Talented & Experienced Marketing Agency
                </li>
                <li class="mb-1 flex">
                  <i class="uil uil-check-circle text-indigo-600 text-xl mr-2"></i>{" "}
                  Create your own skin to match your brand
                </li>
              </ul> */}

              <div class="mt-4">
                <a
                  href="about_us"
                  class="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3 btn-sm"
                >
                  Read More <i class="mdi mdi-chevron-right align-middle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFormaxPay;
