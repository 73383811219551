import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer class="footer relative bg-frmx text-white-200 white:text-white-200 mt-16">
        <div class="container">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <div class="py-[60px] px-0">
                <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                  <div class="lg:col-span-3 md:col-span-12">
                    <a href="#" class="text-[22px] focus:outline-none">
                      <img
                        src="images/white-logo.png"
                        alt="formax-pay-white-logo"
                        width={150}
                      />
                    </a>
                    <p class="mt-3 text-white">
                      Formax Pay is a robust financial technology platform. We
                      at Formax Pay, our focus is on making financial services
                      more simpler, safer & smarter for our customers by
                      simplifying finance with latest technology.
                    </p>
                    <ul class="list-none mt-6 text-white">
                      <li class="inline">
                        <a
                          href="https://www.facebook.com/formaxitsolutions"
                          target="_blank"
                          class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i
                            class="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </a>
                      </li>
                      <li class="inline ml-1">
                        <a
                          href="https://twitter.com/formax_it"
                          target="_blank"
                          class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i
                            class="uil uil-twitter align-middle"
                            title="twitter"
                          ></i>
                        </a>
                      </li>
                      <li class="inline ml-1">
                        <a
                          href="https://www.instagram.com/formaxpay"
                          target="_blank"
                          class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i
                            class="uil uil-instagram align-middle"
                            title="instagram"
                          ></i>
                        </a>
                      </li>

                      <li class="inline ml-1">
                        <a
                          href="http://linkedin.com/company/formax-pay"
                          target="_blank"
                          class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <i class="uil uil-linkedin" title="Linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="lg:col-span-3 md:col-span-3">
                    <h5 class="text-white tracking-[1px] font-semibold">
                      Products
                    </h5>
                    <ul class="list-none footer-list mt-6">
                      <li>
                        <a
                          href=""
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Banking
                          Services
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href=""
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Payment
                          Services
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href=""
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i>{" "}
                          e-Governance Services
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href=""
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Tours &
                          Travel Services
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href=""
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Insurance
                          Services
                        </a>
                      </li>
                    </ul>

                    {/* <div class="mt-2">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.formaxit.formaxpay"
                        target="_blank"
                      >
                        <img
                          src="assets/images/app/playstore.png"
                          class="inline-block m-1"
                          alt=""
                          width={100}
                        />
                      </a>
                    </div> */}
                  </div>

                  <div class="lg:col-span-3 md:col-span-3">
                    <h5 class="tracking-[1px] text-gray-100 font-semibold">
                      Quick Links
                    </h5>
                    <ul class="list-none footer-list mt-6">
                      <li>
                        <a
                          href="about_us"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> About Us
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="contact_us"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Contact Us
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="terms_and_conditions"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Terms and
                          Conditions
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="/static/ppi_tnc.pdf"
                          target="_blank"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Formax Pay
                          Card - T&C
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="privacy_policy"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Privacy
                          Policy
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="grievance_policy"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Grievance
                          Policy
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="refund_policy"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Refund
                          Policy
                        </a>
                      </li>
                      <li class="mt-[10px]">
                        <a
                          href="https://docs.formaxpay.com"
                          class="text-white hover:text-gray-400 duration-500 ease-in-out"
                          target="_blank"
                        >
                          <i class="uil uil-angle-right-b me-1"></i> Developer
                          APIs
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="lg:col-span-3 md:col-span-3">
                    <h5 class="text-white tracking-[1px] font-semibold">
                      Contact Us
                    </h5>
                    <p class="mt-3 text-white">
                      <i className="uil uil-map-marker"></i> Formax It Solutions
                      Pvt Ltd, Ground Floor, 16, Mochpole, Near Junior School,
                      Nabapally, Barasat, Kolkata - 700126, West Bengal
                    </p>

                    <p class="mt-2 text-white">
                      <i className="uil uil-envelope"></i>{" "}
                      helpdesk@formaxpay.com
                    </p>

                    <p class="mt-2 text-white">
                      <i className="uil uil-phone"></i> 7011-8161-00{" "}
                    </p>
                    <p class="mt-2 text-white">
                      <i className="uil uil-phone"></i> 0120-685-0678
                    </p>
                  </div>
                </div>
              </div>

              <p className="text-white">
                CIN: U72900WB2018PTC228240 | GSTIN: 19AADCF5125F1ZT{" "}
              </p>
              <p
                className="mb-8 mt-2 text-white"
                style={{ textAlign: "justify" }}
              >
                {" "}
                Please do not share your account details such as password, card
                number, expiry date, CVV, OTP, PIN and other confidential
                information with anyone (including Formax Pay and Bank
                officials) or on any mail received as such communications are
                sent with malicious intent to dupe the customers. Formax Pay and
                Bank never ask for such details.
              </p>
            </div>
          </div>
        </div>

        <div class="py-[30px] px-0 border-t border-slate-800">
          <div class="container text-center">
            <div class="grid md:grid-cols-2 items-center">
              <div class="md:text-left text-center">
                <p class="mb-0 text-white">
                  Copyright © {new Date().getFullYear()} Formax It Solutions
                  Private Limited
                </p>
              </div>

              <ul class="text-white list-none md:text-right text-center mt-6 md:mt-0">
                Made with <i class="mdi mdi-heart text-red-600"></i> in India
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <a
        href="#"
        onclick="topFunction()"
        id="back-to-top"
        class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 right-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"
      >
        <i class="uil uil-arrow-up"></i>
      </a>
    </React.Fragment>
  );
};

export default Footer;
