import React from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";
import AboutFormaxPay from "./AboutFormaxPay";
import BusinessPartner from "./BusinessPartner";
import DownloadApp from "./DownloadApp";
import StartHero from "./StartHero";
import Testimonials from "./Testimonials";

const Home = () => {
  return (
    <React.Fragment>
      <NavBar />
      <StartHero />
      <BusinessPartner />
      <AboutFormaxPay />
      <DownloadApp />
      <Testimonials />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
