import React from "react";

const BusinessPartner = () => {
  return (
    <section class="py-6 border-t border-b border-gray-100 dark:border-gray-700">
      <div class="container">
        <div class="grid grid-cols-1 pb-4 text-center">
          <h6 class="text-slate-400 text-lg">Our Trusted Partners</h6>
        </div>

        <div class="grid md:grid-cols-4 grid-cols-2 justify-center gap-[30px]">
          <div class="mx-auto py-4">
            <img src="images/axis-bank.png" class="h-8" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="images/yes-bank.png" class="h-8" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="images/airtel-bank.png" class="h-8" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="images/nsdl.png" class="h-8" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessPartner;
