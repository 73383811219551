import React from "react";

const DownloadApp = () => {
  return (
    <section class="w-full table relative bg-frmx">
      <div class="absolute inset-0 bg-[url('../../assets/images/insurance/cta.html')] bg-bottom bg-no-repeat"></div>
      <div class="relative">
        <div class="container z-2">
          <div class="grid md:grid-cols-12 grid-cols-1 items-center">
            <div class="lg:col-span-5 md:col-span-6">
              <img
                src="images/download-app-2.png"
                class="mx-auto d-block"
                alt=""
              />
            </div>

            <div class="lg:col-span-7 md:col-span-6">
              <h4 class="md:text-3xl text-2xl lg:leading-normal leading-normal font-medium my-4 text-white">
                Download the Formax Pay App Now!
              </h4>
              <p class="text-slate-400 max-w-xl mb-0 text-white">
                Do you want to take your business to the next level? Join with
                Formax Pay now with zero investment and increase your earning.
              </p>
              <div class="my-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.formaxit.formaxpay"
                  target="_blank"
                >
                  <img
                    src="assets/images/app/playstore.png"
                    class="inline-block"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
