import React from "react";

const StartHero = () => {
  return (
    <section class="relative overflow-hidden md:pt-44 pt-36 md:pb-24 pb-16">
      <div class="container relative">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px] relative">
          <div class="md:mr-6">
            <ul class="list-none mb-0 text-amber-400 text-xl">
              <li class="inline">
                <i class="mdi mdi-star"></i>
              </li>
              <li class="inline">
                <i class="mdi mdi-star"></i>
              </li>
              <li class="inline">
                <i class="mdi mdi-star"></i>
              </li>
              <li class="inline">
                <i class="mdi mdi-star"></i>
              </li>
              <li class="inline">
                <i class="mdi mdi-star"></i>
              </li>
            </ul>
            <h4 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-4xl mb-5 text-black dark:text-white">
              Smart Banking Platform
              <br />{" "}
              <span class="after:absolute after:right-0 after:left-0 after:bottom-3 after:-rotate-6 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/20 relative text-frmx">
                for Digital Bharat{" "}
              </span>
            </h4>
            <p class="text-slate-400 text-lg max-w-xl">
              Become a <b>Formax Pay</b> retailer and offer banking, payments,
              collections, travel, insurance, and e-governance services to your
              customers at your store.
            </p>

            <div class="mt-6">
              <p>
                <strong class="m-1">Download the Formax Pay App!</strong>
              </p>
              <div class="mt-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.formaxit.formaxpay"
                  target="_blank"
                >
                  <img
                    src="assets/images/app/playstore.png"
                    class="inline-block m-1"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>

          <div class="relative">
            <img
              src="images/formaxpay-app.gif"
              width={700}
              class="mx-auto"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* 
      <div class="container md:mt-24 mt-16">
        <div class="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
          <div class="mx-auto py-4">
            <img src="assets/images/client/9.png" class="h-20" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="assets/images/client/10.png" class="h-20" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="assets/images/client/11.png" class="h-20" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="assets/images/client/12.png" class="h-20" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="assets/images/client/13.png" class="h-20" alt="" />
          </div>

          <div class="mx-auto py-4">
            <img src="assets/images/client/14.png" class="h-20" alt="" />
          </div>
        </div>
      </div>

      <div class="container md:mt-24 mt-16">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
          <div class="relative">
            <div class="relative">
              <img
                src="assets/images/avatar.jpg"
                class="mx-auto md:max-w-xs lg:max-w-sm rounded-lg shadow-md dark:shadow-gray-800"
                alt=""
              />

              <div class="absolute top-4 -right-2 md:right-14 text-center">
                <a
                  href="#!"
                  data-type="youtube"
                  data-id="yba7hPeTSjk"
                  class="lightbox h-20 w-20 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center text-white bg-indigo-600"
                >
                  <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                </a>
              </div>
            </div>
            <div class="overflow-hidden absolute md:h-[500px] h-[400px] md:w-[500px] w-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:right-0 right-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div>

            <div class="absolute flex justify-between items-center bottom-16 md:-left-10 -left-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-64 m-3">
              <div class="flex items-center">
                <div class="flex items-center justify-center h-[65px] min-w-[65px] bg-indigo-600/5 text-indigo-600 text-center rounded-full mr-3">
                  <i data-feather="dollar-sign" class="h-6 w-6"></i>
                </div>
                <div class="flex-1">
                  <h6 class="text-slate-400">Revenue</h6>
                  <p class="text-xl font-bold">
                    $
                    <span class="counter-value" data-target="45485">
                      39548
                    </span>
                  </p>
                </div>
              </div>

              <span class="text-green-600">
                <i class="uil uil-arrow-growth"></i> 3.85%
              </span>
            </div>
          </div>

          <div class="lg:ml-8">
            <h4 class="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">
              Ready to kick off <br /> your startup journey?
            </h4>
            <p class="text-slate-400 mb-6">
              Due to its widespread use as filler text for layouts,
              non-readability is of great importance: human perception is tuned
              to recognize certain patterns and repetitions in texts. If the
              distribution of letters visual impact.
            </p>

            <div class="pt-6 flex items-center border-t dark:border-gray-700">
              <img
                src="assets/images/client/05.jpg"
                class="h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mr-3"
                alt=""
              />
              <div class="flex-1">
                <h6 class="text-lg text-slate-400 italic font-semibold">
                  " Human perception is tuned to recognize certain patterns and
                  repetitions in texts. "
                </h6>
                <p class="font-semibold">Owner & CEO</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* 
      <div class="container md:mt-24 mt-16">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
          <div class="relative order-1 md:order-2">
            <div class="grid grid-cols-12 items-center gap-4 lg:gap-0">
              <div class="col-span-6">
                <div class="grid grid-cols-1 lg:gap-6 gap-4">
                  <img
                    src="assets/images/notification.jpg"
                    class="shadow rounded-md lg:max-w-[240px] mx-auto"
                    alt=""
                  />
                  <img
                    src="assets/images/about/ab02.jpg"
                    class="shadow rounded-md lg:max-w-[240px] mx-auto"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-span-6">
                <div class="grid grid-cols-1">
                  <img
                    src="assets/images/about/ab01.jpg"
                    class="shadow rounded-md lg:max-w-[240px] mx-auto"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="overflow-hidden absolute md:h-[500px] h-[400px] md:w-[500px] w-[400px] bg-gradient-to-tl to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:left-0 left-1/2 md:translate-x-0 -translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div>

            <div class="absolute flex justify-between items-center md:bottom-16 bottom-0 right-5 rounded-lg shadow-md dark:shadow-gray-800 lg:w-72 w-48 m-3">
              <img
                src="assets/images/sales-chart.jpg"
                class="rounded-lg shadow dark:shadow-gray-800"
                alt=""
              />
            </div>
          </div>

          <div class="lg:mr-8 order-2 md:order-1">
            <h4 class="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">
              If you need help, <br /> we're here for you
            </h4>

            <div
              id="accordion-collapseone"
              data-accordion="collapse"
              class="mt-6"
            >
              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden">
                <h2
                  class="text-base font-semibold"
                  id="accordion-collapse-heading-1"
                >
                  <button
                    type="button"
                    class="flex justify-between items-center p-5 w-full font-medium text-left"
                    data-accordion-target="#accordion-collapse-body-1"
                    aria-expanded="true"
                    aria-controls="accordion-collapse-body-1"
                  >
                    <span>How does it work ?</span>
                    <svg
                      data-accordion-icon
                      class="w-4 h-4 rotate-180 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-1"
                  class="hidden"
                  aria-labelledby="accordion-collapse-heading-1"
                >
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form.
                    </p>
                  </div>
                </div>
              </div>

              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                <h2
                  class="text-base font-semibold"
                  id="accordion-collapse-heading-2"
                >
                  <button
                    type="button"
                    class="flex justify-between items-center p-5 w-full font-medium text-left"
                    data-accordion-target="#accordion-collapse-body-2"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-2"
                  >
                    <span>Do I need a designer to use Techwind ?</span>
                    <svg
                      data-accordion-icon
                      class="w-4 h-4 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-2"
                  class="hidden"
                  aria-labelledby="accordion-collapse-heading-2"
                >
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form.
                    </p>
                  </div>
                </div>
              </div>

              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                <h2
                  class="text-base font-semibold"
                  id="accordion-collapse-heading-3"
                >
                  <button
                    type="button"
                    class="flex justify-between items-center p-5 w-full font-medium text-left"
                    data-accordion-target="#accordion-collapse-body-3"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-3"
                  >
                    <span>What do I need to do to start selling ?</span>
                    <svg
                      data-accordion-icon
                      class="w-4 h-4 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-3"
                  class="hidden"
                  aria-labelledby="accordion-collapse-heading-3"
                >
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form.
                    </p>
                  </div>
                </div>
              </div>

              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                <h2
                  class="text-base font-semibold"
                  id="accordion-collapse-heading-4"
                >
                  <button
                    type="button"
                    class="flex justify-between items-center p-5 w-full font-medium text-left"
                    data-accordion-target="#accordion-collapse-body-4"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-4"
                  >
                    <span>What happens when I receive an order ?</span>
                    <svg
                      data-accordion-icon
                      class="w-4 h-4 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-4"
                  class="hidden"
                  aria-labelledby="accordion-collapse-heading-4"
                >
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <a
                href="page-aboutus.html"
                class="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Find Out More <i class="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {/* 
      <div class="container md:mt-24 mt-16">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
          <div class="relative">
            <img src="assets/images/app/classic04.png" class="mx-auto" alt="" />
            <div class="overflow-hidden absolute md:h-[500px] h-[400px] md:w-[500px] w-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:right-0 right-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div>

            <div class="absolute bottom-1/2 translate-y-1/2 lg:-right-7 -right-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-52 m-3">
              <div class="flex">
                <div class="text-center min-w-[75px]">
                  <span class="text-2xl font-bold">4.90</span>
                  <ul class="list-none mb-0 text-amber-400 text-xs">
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                  <span class="text-xs">
                    <span class="font-semibold">950</span>{" "}
                    <span class="text-slate-400">Ratings</span>
                  </span>
                </div>

                <span class="font-bold ml-3">
                  Trusted by over <span class="text-indigo-600">50000</span>{" "}
                  members
                </span>
              </div>
            </div>

            <div class="absolute flex justify-between items-center bottom-16 md:left-10 -left-2 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-56 m-3">
              <div class="flex items-center">
                <img
                  src="assets/images/client/01.jpg"
                  class="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mr-3"
                  alt=""
                />
                <div class="flex-1">
                  <h6 class="text-lg font-semibold">Advin</h6>
                  <p class="text-slate-400">Owner & CEO</p>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:ml-8">
            <h4 class="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">
              Plan in front program for your needs
            </h4>
            <p class="text-slate-400">
              Due to its widespread use as filler text for layouts,
              non-readability is of great importance: human perception is tuned
              to recognize certain patterns and repetitions in texts. If the
              distribution of letters visual impact.
            </p>
            <ul class="list-none text-slate-400 mt-4">
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl mr-2"></i>{" "}
                Digital Marketing Solutions for Tomorrow
              </li>
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl mr-2"></i>{" "}
                Our Talented & Experienced Marketing Agency
              </li>
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl mr-2"></i>{" "}
                Create your own skin to match your brand
              </li>
            </ul>

            <div class="mt-4">
              <a
                href="page-aboutus.html"
                class="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Find Out More <i class="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div class="container md:mt-24 mt-16">
        <div class="grid grid-cols-1 pb-8 text-center">
          <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Key Features
          </h3>
          <p class="text-slate-400 max-w-xl mx-auto">
            Create, collaborate, and turn your ideas into incredible products
            with the definitive platform for digital design.
          </p>
        </div>

        <div class="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] items-center">
          <div class="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
            <div class="grid grid-cols-1 gap-[30px]">
              <div class="group flex transition-all duration-500 ease-in-out xl:p-3">
                <div class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                  <i data-feather="monitor" class="w-5 h-5"></i>
                </div>
                <div class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4">
                  <h4 class="mb-0 text-lg font-medium">Use On Any Device</h4>
                  <p class="text-slate-400 mt-3">
                    One disadvantage of Lorum Ipsum is that in Latin frequently
                    than others
                  </p>
                </div>
              </div>

              <div class="group flex transition-all duration-500 ease-in-out xl:p-3">
                <div class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                  <i data-feather="feather" class="w-5 h-5"></i>
                </div>
                <div class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4">
                  <h4 class="mb-0 text-lg font-medium">Feather Icons</h4>
                  <p class="text-slate-400 mt-3">
                    One disadvantage of Lorum Ipsum is that in Latin frequently
                    than others
                  </p>
                </div>
              </div>

              <div class="group flex transition-all duration-500 ease-in-out xl:p-3">
                <div class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                  <i data-feather="eye" class="w-5 h-5"></i>
                </div>
                <div class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4">
                  <h4 class="mb-0 text-lg font-medium">Retina Ready</h4>
                  <p class="text-slate-400 mt-3">
                    One disadvantage of Lorum Ipsum is that in Latin frequently
                    than others
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="lg:col-span-4 md:col-span-12 lg:mx-8 lg:order-2 order-1">
            <img
              src="assets/images/feature.png"
              class="mx-auto md:max-w-[300px]"
              alt=""
            />
          </div> 

           <div class="lg:col-span-4 md:col-span-6 order-3">
            <div class="grid grid-cols-1 gap-[30px]">
              <div class="group flex transition-all duration-500 ease-in-out xl:p-3">
                <div class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                  <i data-feather="user-check" class="w-5 h-5"></i>
                </div>
                <div class="flex-1 ml-4">
                  <h4 class="mb-0 text-lg font-medium">W3c Valid Code</h4>
                  <p class="text-slate-400 mt-3">
                    One disadvantage of Lorum Ipsum is that in Latin frequently
                    than others
                  </p>
                </div>
              </div>

              <div class="group flex transition-all duration-500 ease-in-out xl:p-3">
                <div class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                  <i data-feather="smartphone" class="w-5 h-5"></i>
                </div>
                <div class="flex-1 ml-4">
                  <h4 class="mb-0 text-lg font-medium">Fully Responsive</h4>
                  <p class="text-slate-400 mt-3">
                    One disadvantage of Lorum Ipsum is that in Latin frequently
                    than others
                  </p>
                </div>
              </div>

              <div class="group flex transition-all duration-500 ease-in-out xl:p-3">
                <div class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                  <i data-feather="heart" class="w-5 h-5"></i>
                </div>
                <div class="flex-1 ml-4">
                  <h4 class="mb-0 text-lg font-medium">
                    Browser Compatibility
                  </h4>
                  <p class="text-slate-400 mt-3">
                    One disadvantage of Lorum Ipsum is that in Latin frequently
                    than others
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>*/}

      {/* <div class="container md:mt-24 mt-16">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-indigo-600 text-base mb-2">Testimonial</h6>
                    <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What Our Users Say</h3>

                    <p class="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                </div>

                <div class="grid grid-cols-1 mt-8">
                    <div class="tiny-three-item">
                        <div class="tiny-slide text-center">
                            <div class="customer-testi">
                                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                                    <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p class="text-slate-400">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "</p>
                                    <ul class="list-none mb-0 text-amber-400 mt-3">
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                                
                                <div class="text-center mt-5">
                                    <img src="assets/images/client/01.jpg" class="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 class="mt-2 font-semibold">Calvin Carlo</h6>
                                    <span class="text-slate-400 text-sm">Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="tiny-slide text-center">
                            <div class="customer-testi">
                                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                                    <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p class="text-slate-400">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                    <ul class="list-none mb-0 text-amber-400 mt-3">
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                                
                                <div class="text-center mt-5">
                                    <img src="assets/images/client/02.jpg" class="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 class="mt-2 font-semibold">Christa Smith</h6>
                                    <span class="text-slate-400 text-sm">Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="tiny-slide text-center">
                            <div class="customer-testi">
                                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                                    <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p class="text-slate-400">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "</p>
                                    <ul class="list-none mb-0 text-amber-400 mt-3">
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                                
                                <div class="text-center mt-5">
                                    <img src="assets/images/client/03.jpg" class="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 class="mt-2 font-semibold">Jemina CLone</h6>
                                    <span class="text-slate-400 text-sm">Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="tiny-slide text-center">
                            <div class="customer-testi">
                                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                                    <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p class="text-slate-400">" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "</p>
                                    <ul class="list-none mb-0 text-amber-400 mt-3">
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                                
                                <div class="text-center mt-5">
                                    <img src="assets/images/client/04.jpg" class="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 class="mt-2 font-semibold">Smith Vodka</h6>
                                    <span class="text-slate-400 text-sm">Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="tiny-slide text-center">
                            <div class="customer-testi">
                                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                                    <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p class="text-slate-400">" There is now an abundance of readable dummy texts. These are usually used when a text is required. "</p>
                                    <ul class="list-none mb-0 text-amber-400 mt-3">
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                                
                                <div class="text-center mt-5">
                                    <img src="assets/images/client/05.jpg" class="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 class="mt-2 font-semibold">Cristino Murfi</h6>
                                    <span class="text-slate-400 text-sm">Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="tiny-slide text-center">
                            <div class="customer-testi">
                                <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
                                    <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p class="text-slate-400">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "</p>
                                    <ul class="list-none mb-0 text-amber-400 mt-3">
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                        <li class="inline"><i class="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                                
                                <div class="text-center mt-5">
                                    <img src="assets/images/client/06.jpg" class="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 class="mt-2 font-semibold">Cristino Murfi</h6>
                                    <span class="text-slate-400 text-sm">Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div class="container md:mt-24 mt-16 z-2">
                <div class="grid md:grid-cols-12 grid-cols-1 items-center">
                    <div class="lg:col-span-5 md:col-span-6">
                        <img src="assets/images/illustrator/envelope.svg" class="mx-auto d-block" alt="" />
                    </div>

                    <div class="lg:col-span-7 md:col-span-6">
                        <span class="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Mobile Apps</span>
                        <h4 class="md:text-3xl text-2xl lg:leading-normal leading-normal font-medium my-4">Available for your <br/> Smartphones</h4>
                        <p class="text-slate-400 max-w-xl mb-0">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                        <div class="my-5">
                            <a href="#"><img src="assets/images/app/app.png" class="m-1 inline-block" alt="" /></a>

                            <a href="#"><img src="assets/images/app/playstore.png" class="m-1 inline-block" alt="" /></a>
                        </div>

                        <div class="inline-block">
                            <div class="pt-4 flex items-center border-t border-gray-100 dark:border-gray-700">
                                <i data-feather="smartphone" class="mr-2 text-indigo-600 h-10 w-10"></i>
                                <div class="content">
                                    <h6 class="text-base font-medium">Install app now on your cellphones</h6>
                                    <a href="#" class="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Learn More <i class="uil uil-angle-right-b"></i></a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div class="container">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
          <div class="md:col-span-7">
            <div class="md:mr-6">
              <h4 class="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
                Smart Banking Platform for{" "}
                <span class="text-indigo-600">Digital Bharat</span>
              </h4>
              <p class="text-slate-400 text-lg max-w-xl">
                We at Formax Pay, our focus is on making financial services more
                simpler, safer & smarter for our customers by simplifying
                finance with latest technology.
              </p>

              <div class="mt-6">
                <a
                  href="contact-one.html"
                  class="btn bg-indigo-100 hover:bg-indigo-200 border-indigo-600 hover:border-indigo-700 text-white rounded-md mr-2 mt-2"
                >
                  <img
                    src="images/google-play.png"
                    alt="google play"
                    width={100}
                  />
                </a>
              </div>
            </div>
          </div>

          <div class="md:col-span-5">
            <img
              src="https://www.formaxpay.com/assets/img/images/banner.png"
              alt=""
            />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default StartHero;
