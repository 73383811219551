import React, { useState, useContext, useRef } from "react";
import Swal from "sweetalert2";

import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

import FormService from "../../Services/FormService";
import LoadingContext from "../../Store/loading-context";
import HttpError from "../../utill/HttpError";

const ContactUs = () => {
  const loadingCtx = useContext(LoadingContext);

  const [message, setMessage] = useState();

  const formRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const subjectRef = useRef();

  const submitHandler = async () => {
    const name = nameRef.current.value;

    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const subject = subjectRef.current.value;

    loadingCtx.startLoading();

    try {
      const responseData = await FormService.contactForm(
        name,
        email,
        mobile,
        subject,
        message
      );

      const status = responseData.status;
      const message2 = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message2, "success");
      } else {
        Swal.fire("Opps!", message2, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <NavBar />

      <section class="relative table w-full py-28 lg:py-12 bg-gray-50 dark:bg-slate-800">
        <div class="container">
          <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">Contact Us</h3>
          </div>
        </div>
      </section>
      <div class="relative">
        <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            class="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section class="relative md:py-24 py-16">
        <div class="container">
          <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div class="text-center px-6 mt-6">
              <div class="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i class="uil uil-phone"></i>
              </div>

              <div class="content mt-7">
                <h5 class="title h5 text-xl font-medium">Phone</h5>
                {/* <p class="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p> */}

                <div class="mt-5">
                  <a
                    href="tel:+152534-468-854"
                    class="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    +91 7011-8161-00 / 0120-685-0678
                  </a>
                  <p>(9.30 AM to 7:30 PM - Monday to Saturday)</p>
                </div>
              </div>
            </div>

            <div class="text-center px-6 mt-6">
              <div class="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i class="uil uil-map-marker"></i>
              </div>

              <div class="content mt-7">
                <h5 class="title h5 text-xl font-medium">Location</h5>
                <p class="text-slate-400 mt-3">
                  Formax It Solutions Pvt Ltd, Ground Floor, N0060, Mochpole,
                  Near Junior School, Nabapally, Barasat, Kolkata - 700126, West
                  Bengal, India
                </p>

                <div class="mt-5">
                  <a
                    href="https://goo.gl/maps/naH8FA2ojSy38LXW6"
                    data-type="iframe"
                    class="video-play-icon read-more lightbox btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    target="_blank"
                  >
                    View on Google map
                  </a>
                </div>
              </div>
            </div>

            <div class="text-center px-2 mt-6">
              <div class="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i class="uil uil-envelope"></i>
              </div>

              <div class="content mt-7">
                <h5 class="title h5 text-xl font-medium">Email</h5>
                {/* <p class="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p> */}

                <div class="mt-5">
                  <a
                    href="mailto:contact@example.com"
                    class="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    helpdesk@formaxpay.com (Support)
                  </a>

                  <a
                    href="mailto:contact@example.com"
                    class="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    partnership@formaxpay.com (Partnership)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container md:mt-24 mt-16">
          <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div class="lg:col-span-7 md:col-span-6">
              <img src="images/contact-us.jpg" alt="" />
            </div>

            <div class="lg:col-span-5 md:col-span-6 mt-8 md:mt-0">
              <div class="lg:ml-5">
                <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 class="mb-6 text-2xl leading-normal font-medium">
                    Get in touch with us!
                  </h3>

                  <form name="myForm" id="myForm" ref={formRef}>
                    <p class="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div class="grid grid-cols-1">
                      <div class="mb-5">
                        <div class="text-left">
                          <label for="name" class="font-semibold">
                            Full Name:
                          </label>
                          <div class="form-icon relative mt-2">
                            <input
                              name="name"
                              type="text"
                              class="form-input"
                              ref={nameRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-1">
                      <div class="mb-5">
                        <div class="text-left">
                          <label for="subject" class="font-semibold">
                            Email Address:
                          </label>
                          <div class="form-icon relative mt-2">
                            <input
                              name="email"
                              class="form-input"
                              ref={emailRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-1">
                      <div class="mb-5">
                        <div class="text-left">
                          <label for="subject" class="font-semibold">
                            Mobile Number:
                          </label>
                          <div class="form-icon relative mt-2">
                            <input
                              name="mobile"
                              class="form-input"
                              ref={mobileRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-1">
                      <div class="mb-5">
                        <div class="text-left">
                          <label for="subject" class="font-semibold">
                            Subject:
                          </label>
                          <div class="form-icon relative mt-2">
                            <i
                              data-feather="book"
                              class="w-4 h-4 absolute top-3 left-4"
                            ></i>
                            <input
                              name="subject"
                              class="form-input"
                              ref={subjectRef}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-5">
                        <div class="text-left">
                          <label for="comments" class="font-semibold">
                            Message:
                          </label>
                          <div class="form-icon relative mt-2">
                            <i
                              data-feather="message-circle"
                              class="w-4 h-4 absolute top-3 left-4"
                            ></i>
                            <textarea
                              name="message"
                              class="form-input h-28"
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                      onClick={submitHandler}
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
