import React from "react";
import { Link } from "react-router-dom";

//import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Login from "@mui/icons-material/Login";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import { blue } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const NavBar = () => {
  return (
    <nav id="topnav" class="defaultscroll is-sticky">
      <div class="container">
        <a class="logo pl-0" href="/">
          <img
            src="images/logo.png"
            width={170}
            class="inline-block dark:hidden"
            alt="formax-pay-logo"
          />
          <img
            src="images/logo.png"
            class="hidden dark:inline-block"
            alt="formax-pay-logo"
          />
        </a>

        <div class="menu-extras">
          <div class="menu-item">
            <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <ul class="buy-button list-none mb-0">
          {/* <li class="inline mb-0">
            <a
              href="https://portal.formaxpay.com/"
              class="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
              target="_blank"
            >
              <i class="uil-sign-out-alt"></i> Login (Old)
            </a>
          </li> */}
          <li class="inline mb-0 ml-2">
            <a
              href="https://app.formaxpay.com/"
              class="btn bg-green-600 hover:bg-green-600-700 bg-green-600-600 hover:bg-green-600-700 text-white rounded-md"
              target="_blank"
            >
              <i class="uil-sign-out-alt"></i> Login
            </a>
          </li>
        </ul>

        <div id="navigation">
          <ul class="navigation-menu">
            <li>
              <a href="/" class="sub-menu-item">
                Home
              </a>
            </li>

            <li>
              <a href="/about_us" class="sub-menu-item">
                About
              </a>
            </li>

            <li class="has-submenu parent-parent-menu-item">
              <a href="javascript:void(0)">Products</a>
              <span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li class="megamenu-head">Banking Services</li>
                    <li>
                      <a href="" class="sub-menu-item">
                        Savings Account (Soon)
                      </a>
                    </li>
                    <li>
                      <a href="" class="sub-menu-item">
                        Current Account (Soon)
                      </a>
                    </li>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li class="megamenu-head">Payment Services</li>
                    <li>
                      <a href="" class="sub-menu-item">
                        Mobile & DTH Recharge
                      </a>
                      <li>
                        <a href="" class="sub-menu-item">
                          Utility Bill Payments
                        </a>
                      </li>
                    </li>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li class="megamenu-head">e-Governance Services</li>
                    <li>
                      <a href="" class="sub-menu-item">
                        NSDL PAN card (E-KYC)
                      </a>
                    </li>
                    <li>
                      <a href="" class="sub-menu-item">
                        UTI PAN card
                      </a>
                    </li>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li class="megamenu-head">Travel Services</li>
                    <li>
                      <a href="" class="sub-menu-item">
                        Flight Booking (Soon)
                      </a>
                    </li>
                    <li>
                      <a href="" class="sub-menu-item">
                        Bus Booking (Soon)
                      </a>
                    </li>
                    <li>
                      <a href="" class="sub-menu-item">
                        Hotel Booking (Soon)
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <a href="https://docs.formaxpay.com/" target="_blank">
                Developer APIs
              </a>
            </li>

            <li>
              <a href="/contact_us" class="sub-menu-item">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
