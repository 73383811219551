import React from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";
import style from "./style.css";

const RefundPolicy = () => {
  return (
    <React.Fragment>
      <NavBar />

      <section class="relative table w-full py-28 lg:py-12 bg-gray-50 dark:bg-slate-800">
        <div class="container">
          <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">Refund Policy</h3>
          </div>
        </div>
      </section>
      <div class="relative">
        <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            class="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section class="relative">
        <div class="container">
          <div class="md:flex justify-center">
            <div class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
              <h5 class="text-xl font-semibold mb-4">
                Formax It Solutions Pvt Ltd liability for refund :
              </h5>

              <p className="text-slate-400">
                1. We will refund the amount in case a transaction is failed due
                to any reason directly caused by Formax IT Solutions Pvt Ltd.
                Once Formax IT Solutions Pvt Ltd receives a relevant
                confirmation from the payment gateway, a proper refund will be
                processed to the user’s bank account and it will take 3-21
                working days to reflect from the date of transaction.
                Confirmation about the same will be notified to the User’s
                registered Email ID. Kindly note, a refund will be processed
                only for the transaction amount, and not for any payment gateway
                charges or applicable taxes.
              </p>
              <p className="text-slate-400">
                2. We will refund the amount in case the user has paid for some
                services, got confirmation about the payment but does not
                receive those services to utilize. In such cases, we request the
                user to drop us a complaint on our official Email ID and let us
                evaluate the scenario. Once we investigate and make a
                conclusion, we will refund your amount to your bank account
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Formax It Solutions Pvt Ltd non-liability for refund :
              </h5>

              <p className="text-slate-400">
                1. We will not be liable for any refund if a transaction is
                failed due to network error, electricity issues, or any other
                such reasons. Formax IT Solutions Pvt Ltd will not be
                responsible for any failure caused due to irrelevant and invalid
                reasons.
              </p>
              <p className="text-slate-400">
                2. We will not be liable for any refund after the purchase. Once
                the user agrees to use our services and conducts the payment,
                Formax IT Solutions Pvt Ltd will not be responsible for any
                refund.
              </p>
              <p class="text-slate-400 mb-10">
                2. We will not be liable for any refund if the user fails to
                perform KYC at the initial stage. Each Formax IT Solutions Pvt
                Ltd user has to go through a successful KYC verification once.
                Thus, it is mandatory to keep the correct KYC documents ready
                before initiating any payment to Formax IT Solutions Pvt Ltd.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default RefundPolicy;
