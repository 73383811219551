import React from "react";

const Testimonials = () => {
  return (
    <div class="container md:mt-24 mt-16">
      <div class="grid grid-cols-1 pb-8 text-center">
        <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
          What Our Users Say
        </h3>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
        <div class="tiny-slide text-center">
          <div class="customer-testi">
            <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
              <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
              <p class="text-slate-400">
                " Formax Pay is one of the best payment apps I have come across.
                The app offering services such as Bill Payments, Recharge, and
                PAN card issuance is great great great. I love this application.
                "
              </p>
              <ul class="list-none mb-0 text-amber-400 mt-3">
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
              </ul>
            </div>

            <div class="text-center mt-5">
              <img
                src="images/user-icon.png"
                class="h-14 w-14 rounded-full shadow-md mx-auto"
                alt=""
              />
              <h6 class="mt-2 font-semibold">Inda Sarkar</h6>
              <span class="text-slate-400 text-sm">Retailer, West Bengal</span>
            </div>
          </div>
        </div>

        <div class="tiny-slide text-center">
          <div class="customer-testi">
            <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
              <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
              <p class="text-slate-400">
                " Formax Pay is the most user friendly app & reliable app. Best
                service all department. Using since last 2 year & their is no
                problem or any kind of issues for me. This is what we want & all
                of your services are great. "
              </p>
              <ul class="list-none mb-0 text-amber-400 mt-3">
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
              </ul>
            </div>

            <div class="text-center mt-5">
              <img
                src="images/user-icon.png"
                class="h-14 w-14 rounded-full shadow-md mx-auto"
                alt=""
              />
              <h6 class="mt-2 font-semibold">Sk Maher</h6>
              <span class="text-slate-400 text-sm">Retailer, Tamil Nadu</span>
            </div>
          </div>
        </div>

        <div class="tiny-slide text-center">
          <div class="customer-testi">
            <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900">
              <i class="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
              <p class="text-slate-400">
                " Thank you so much team Formax pay for this wonderful app. My
                retailers are very satisfied. Great & genuine app for recharge,
                bill payments etc. Pls add account opening as soon as possible.
                "
              </p>
              <ul class="list-none mb-0 text-amber-400 mt-3">
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
                <li class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
              </ul>
            </div>

            <div class="text-center mt-5">
              <img
                src="images/user-icon.png"
                class="h-14 w-14 rounded-full shadow-md mx-auto"
                alt=""
              />
              <h6 class="mt-2 font-semibold">Vijay Nagar</h6>
              <span class="text-slate-400 text-sm">Distributor, Rajasthan</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
