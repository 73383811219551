import React from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";
import style from "./style.css";

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <NavBar />

      <section class="relative table w-full py-28 lg:py-12 bg-gray-50 dark:bg-slate-800">
        <div class="container">
          <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">
              Terms and Conditions
            </h3>
          </div>
        </div>
      </section>
      <div class="relative">
        <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            class="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section class="relative">
        <div class="container">
          <div class="md:flex justify-center">
            <div class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
              <p className="text-slate-400">
                These terms and conditions outline the rules and regulations for
                the use of Formax Pay Website, located at formaxpay.com
              </p>

              <p className="text-slate-400">
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use Formax Pay if you do not
                agree to take all of the terms and conditions stated on this
                page.
              </p>

              <p className="text-slate-400">
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company’s terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client’s needs in respect of
                provision of the Company’s stated services, in accordance with
                and subject to, prevailing law of Netherlands. Any use of the
                above terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">Cookies :</h5>
              <p className="text-slate-400">
                We employ the use of cookies. By accessing Formax Pay, you
                agreed to use cookies in agreement with the Formax Pay Privacy
                Policy.{" "}
              </p>
              <p className="text-slate-400">
                Most interactive websites use cookies to let us retrieve the
                user’s details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">License :</h5>

              <p className="text-slate-400">
                Unless otherwise stated, Formax Pay and/or its licensors own the
                intellectual property rights for all material on Formax Pay. All
                intellectual property rights are reserved. You may access this
                from Formax Pay for your own personal use subjected to
                restrictions set in these terms and conditions.
              </p>

              <p className="text-slate-400">You must not:</p>
              <p className="text-slate-400">
                1. Republish material from Formax Pay
              </p>
              <p className="text-slate-400">
                2. Sell, rent or sub-license material from Formax Pay
              </p>
              <p className="text-slate-400">
                4. Reproduce, duplicate or copy material from Formax Pay
              </p>
              <p className="text-slate-400">
                5. Redistribute content from Formax Pay
              </p>

              <p className="text-slate-400">
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. Formax Pay does not filter, edit, publish or review
                Comments prior to their presence on the website. Comments do not
                reflect the views and opinions of Formax Pay,its agents and/or
                affiliates. Comments reflect the views and opinions of the
                person who post their views and opinions. To the extent
                permitted by applicable laws, Formax Pay shall not be liable for
                the Comments or for any liability, damages or expenses caused
                and/or suffered as a result of any use of and/or posting of
                and/or appearance of the Comments on this website.
              </p>

              <p className="text-slate-400">
                Formax Pay reserves the right to monitor all Comments and to
                remove any Comments which can be considered inappropriate,
                offensive or causes breach of these Terms and Conditions.
              </p>

              <p className="text-slate-400">You warrant and represent that:</p>

              <p className="text-slate-400">
                1. You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so;
              </p>
              <p className="text-slate-400">
                2. The Comments do not invade any intellectual property right,
                including without limitation copyright, patent or trademark of
                any third party;
              </p>
              <p className="text-slate-400">
                3. The Comments do not contain any defamatory, libelous,
                offensive, indecent or otherwise unlawful material which is an
                invasion of privacy
              </p>
              <p className="text-slate-400">
                4. The Comments will not be used to solicit or promote business
                or custom or present commercial activities or unlawful activity.
              </p>

              <p className="text-slate-400">
                You hereby grant Formax Pay a non-exclusive license to use,
                reproduce, edit and authorize others to use, reproduce and edit
                any of your Comments in any and all forms, formats or media.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Hyperlinking to our Content :
              </h5>

              <p className="text-slate-400">
                The following organizations may link to our Website without
                prior written approval:
              </p>

              <p className="text-slate-400">1. Government agencies;</p>
              <p className="text-slate-400">2. Search engines;</p>
              <p className="text-slate-400">3. News organizations;</p>
              <p className="text-slate-400">
                4. Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </p>
              <p className="text-slate-400">
                5. System wide Accredited Businesses except soliciting
                non-profit organizations, charity shopping malls, and charity
                fundraising groups which may not hyperlink to our Web site.
              </p>

              <p className="text-slate-400">
                These organizations may link to our home page, to publications
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party’s site.
              </p>

              <p className="text-slate-400">
                We may consider and approve other link requests from the
                following types of organizations:
              </p>

              <p className="text-slate-400">
                1. commonly-known consumer and/or business information sources;
              </p>
              <p className="text-slate-400">2. dot.com community sites;</p>
              <p className="text-slate-400">
                3. associations or other groups representing charities;
              </p>
              <p className="text-slate-400">
                4. online directory distributors;
              </p>
              <p className="text-slate-400">5. internet portals;</p>
              <p className="text-slate-400">
                6. accounting, law and consulting firms; and
              </p>
              <p className="text-slate-400">
                7. educational institutions and trade associations.
              </p>

              <p className="text-slate-400">
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of Formax Pay; and (d) the link is in the context of general
                resource information.
              </p>

              <p className="text-slate-400">
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party’s site.
              </p>

              <p className="text-slate-400">
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to Formax Pay. Please include your name,
                your organization name, contact information as well as the URL
                of your site, a list of any URLs from which you intend to link
                to our Website, and a list of the URLs on our site to which you
                would like to link. Wait 2-3 weeks for a response.
              </p>

              <p className="text-slate-400">
                Approved organizations may hyperlink to our Website as follows:
              </p>

              <p className="text-slate-400">
                1. By use of our corporate name; or
              </p>
              <p className="text-slate-400">
                2. By use of the uniform resource locator being linked to; or
              </p>
              <p className="text-slate-400">
                3. By use of any other description of our Website being linked
                to that makes sense within the context and format of content on
                the linking party’s site.
              </p>

              <p className="text-slate-400">
                No use of Formax Pay logo or other artwork will be allowed for
                linking absent a trademark license agreement.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">iFrames :</h5>
              <p className="text-slate-400">
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Content Liability :
              </h5>
              <p className="text-slate-400">
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">Your Privacy :</h5>
              <p className="text-slate-400">Please read Privacy Policy</p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Reservation of Rights :
              </h5>
              <p className="text-slate-400">
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it’s linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Removal of links from our website :
              </h5>
              <p className="text-slate-400">
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>

              <p className="text-slate-400">
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">Settlement :</h5>

              <p className="text-slate-400">
                All settlements shall be in accordance with the RBI rules,
                regulations and guidelines as applicable
              </p>
              <p className="text-slate-400">
                FORMAX shall settle Transactions carried out by Merchant through
                Nodal/Acquiring Bank
              </p>
              <p className="text-slate-400">
                FORMAX shall process all valid Transactions of the Merchant
                through the Transaction Gateway Partners
              </p>
              <p className="text-slate-400">
                FORMAX may provisionally credit the value of the processed
                Transactions to the Merchant Account
              </p>
              <p className="text-slate-400">
                FORMAX may refuse to accept any Charge slip or revoke its prior
                acceptance of a Charge slip in the following circumstances: (a)
                the transaction giving rise to the Charge slip was not made in
                compliance with all the terms and conditions of this Agreement,
                or; (b) the Card Holder disputes his liability to Merchant/
                FORMAX for any reason, including but not limited to those
                Chargeback rights enumerated in the Rules, or; (c) the
                transaction giving rise to the Charge slip was not directly
                between Merchant and Card Holder, or; (d) the transaction is
                outside the parameters indicated on the Merchant Application.
                Merchant shall pay FORMAX and for any amount previously credited
                to the Merchant for a Charge slip not accepted or later revoked
                by them
              </p>
              <p className="text-slate-400">
                Merchant shall maintain one or more Settlement Accounts, for
                payment of the Transaction Amount less the charges, fees and
                other payments set out in Schedule. If Merchant intends to
                change the Settlement Account, Merchant must notify FORMAX with
                all relevant documentation for re-verification, and the same
                shall be affected within 3 working days of receipt of the
                Merchant’s notice with complete documentation after the
                re-verification is completed. Merchant shall pay FORMAX the
                Merchant Settlement Account Change Fee
              </p>
              <p className="text-slate-400">
                FORMAX shall not be liable for any liability whatsoever
                including on account of delay in receipt of funds or errors in
                debit and credit entries caused by third parties including
                without limitation, any Card Brand, Acquiring Bank, Nodal Bank,
                Merchant’s Bank, couriers, communication carriers, servers and
                internet service providers, data processors, clearing houses or
                payment gateways, or any failure in electronic fund transfers,
                Server/system /network failure or any other glitches which are
                beyond the control of FORMAX
              </p>
              <p className="text-slate-400">
                Monthly statements shall be available for viewing through the
                Merchant’s online account with FORMAX. All statements may be
                accessed from the Account Login on the website of FORMAX.
                Printed statements can also be obtained from FORMAX by paying
                Transaction Statement Fee. Merchant shall promptly examine the
                statements and notify FORMAX by email of all errors by providing
                Merchant’s name and Account number within 7 days for the
                occurrence of the error. FORMAX shall investigate the same
                within 30 days of receiving the email
              </p>
              <p className="text-slate-400">
                Merchant authorizes FORMAX to initiate reversal or adjustment
                entries and initiate or suspend such entries necessary to grant
                Merchant conditional credit/debit. Merchant shall pay to FORMAX
                any charges and costs demanded by others or incurred by FORMAX
                on the Transactions
              </p>
              <p className="text-slate-400">
                If FORMAX is of the opinion that a particular transaction is
                fraudulent or suspicious or incorrect data has been inserted by
                Merchant, Merchant alone shall be liable for the same
              </p>
              <p className="text-slate-400">
                Merchant’s policy for the warranty/guarantee, exchange, return
                or replacement of goods sold and adjustment for services
                rendered and shall clearly specified on the invoice raised by
                Merchant. If Merchant fails to do so, a full refund in the form
                of a credit to the Card Holder’s Card account must be given by
                Merchant. Merchant shall not refund cash to a Card Holder who
                paid for the goods / service by Card. Credits in cases of refund
                must be made to the same Card account number on which the
                original Transaction was processed. To enable such refund,
                Merchant shall at all times maintain sufficient funds in the
                Merchant Account
              </p>
              <p className="text-slate-400">
                FORMAX shall be entitled to establish and maintain for the
                Merchant a Reserve Account at any time prior to or after
                termination of this Agreement (with or without notice to
                Merchant), to ensure recovery of any liabilities owed by
                Merchant or reasonably anticipated by FORMAX to be owed by
                Merchant pursuant to this Agreement. FORMAX shall be entitled to
                do the following in relation to the Reserve Account
              </p>
              <p className="text-slate-400">
                Set-off /deduct amounts arising out of actual and/or potential
                post termination Chargebacks, as well as any and all
                post-termination fees, charges and expenses due or anticipated
                to be due to FORMAX from Merchant;
              </p>
              <p className="text-slate-400">
                Fund and/or replenish the Reserve Account by withholding or
                withdrawing from, or freezing all or any part of, the Merchant
                Account. The Reserve Account shall not bear interest;
              </p>
              <p className="text-slate-400">
                Maintain the FORMAX’s Reserve Account and enforce its rights as
                regards the same event after termination of this Agreement till
                such time as all the payments due by Merchant to FORMAX remain
                outstanding
              </p>
              <p className="text-slate-400">
                <b>Customer Grievance Redressal</b>: FORMAX adhere in addressing
                all customer grievances by following the guidelines and policies
                that are proposed by Regulators including RBI from time to time.
                Any grievances regarding disputed transactions can be raised by
                the customers through FORMAX website via formaxpay.com/customer
                grievance. All grievances will be addressed by FORMAX's
                Grievance officer depending on the nature of the complaint
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Removal of links from our website :
              </h5>
              <p className="text-slate-400">
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>

              <p className="text-slate-400">
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Disclaimer of Warranties{" "}
              </h5>
              <p className="text-slate-400">
                The Merchant represents and warrants that:
              </p>

              <p className="text-slate-400">
                It has had a full and adequate opportunity to read and review
                the Agreement and has had sufficient time to evaluate and
                investigate the provision of services under the Agreement and
                the financial requirements and risks associated with the same
              </p>
              <p className="text-slate-400">
                Neither the execution of the Agreement nor the performance of
                the Merchant’s obligations under the Agreement will result in a
                violation or breach of any other agreement by which the Merchant
                is bound
              </p>
              <p className="text-slate-400">
                Neither the Merchant nor any of the Merchant’s employees or
                agents is under any pre-existing obligation in conflict or in
                any way inconsistent with the provisions of the Agreement
              </p>
              <p className="text-slate-400">
                It has the right to disclose or use all ideas, and other
                information, if any, which the Merchant has gained from third
                parties, and which the Merchant discloses to FORMAX in the
                course of performance of the Agreement, without liability to
                such third parties,
              </p>
              <p className="text-slate-400">
                It shall immediately intimate FORMAX of any violation or
                potential violation of this Agreement, or of other circumstances
                that may cause damage to the goodwill and reputation of FORMAX,
              </p>
              <p className="text-slate-400">
                It shall bear all costs and expenses for traveling, promotional
                activities and other similar out-of-pocket expenses incurred in
                the performance of its duties under the Agreement
              </p>
              <p className="text-slate-400">
                It shall comply with all its obligations pursuant to the
                Agreement and ensure that all payments and collection due from
                it to FORMAX or vice versa are paid in a timely manner in
                accordance with the Agreement
              </p>
              <p className="text-slate-400">
                It shall be responsible to obtain any and all consents and
                approvals that are required from regulatory or governmental
                authorities, including any consent from Customers required to
                transfer data to the FORMAX and/or the FORMAX Platform, as the
                case may be, in accordance with Applicable Law
              </p>
              <p className="text-slate-400">
                It shall ensure regular and timely payment and deposit of all
                taxes, duties and other levies as applicable from time to time
                with the relevant authorities
              </p>
              <p className="text-slate-400">
                All costs associated with the purchasing of software licenses
                and hardware (if any) are to be absolutely and unconditionally
                borne by the Merchant
              </p>
              <p className="text-slate-400">
                It has full power and authority to enter into the Agreement and
                to take any action and execute any documents required by the
                terms hereof and this Agreement has been duly authorized, duly
                and validly executed and delivered, and constitutes a legal,
                valid, and binding obligation, enforceable in accordance with
                the terms hereof; and the persons executing the Agreement on its
                behalf are duly empowered and authorized to execute the
                Agreement and to perform all its obligations in accordance with
                the terms herein.
              </p>
              <p className="text-slate-400">
                It acknowledges that FORMAX shall not be responsible for
                settling third-party disputes that arise out of the provision of
                services provided by the Merchant under this Agreement including
                disputes between the Merchant and its customers. FORMAX may, at
                its sole discretion, provide the Merchant access to audit trail
                documentation from FORMAX’s system and from the Service
                Provider’s system, if so, requested by the Merchant in order to
                settle any Transaction disputes or disputes between the Merchant
                and any of its customer’s. Such an audit trail shall be provided
                to the Merchant within 15 working days of a request, subject to
                the delivery by the concerned Service Provider to FORMAX of the
                required information in a timely manner. The audit trail
                information provided by the Service Provider, pursuant to all
                requests for information by the Merchant, shall be final and
                binding in determining the outcome of Transactions that are
                disputed between FORMAX, the Merchant, and the concerned Service
                Provider’s Customers
              </p>
              <p className="text-slate-400">
                The Merchant shall comply with the provisions contained in the
                Information Technology Act, 2000 and the statutory rules framed
                there under, from time to time, in so far as the same has
                application to its operations in accordance with this Agreement,
                and also with all other Laws, rules and regulations, whether
                already in force or which may be enacted from time to time,
                pertaining to data security and management, data storage,
                sharing and data protection, and various rules, regulation and
                provisions as may be applicable, as and when the same is enacted
                into a law and comes into force, and shall ensure the same level
                of compliance by all its employees
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">Indemnification :</h5>
              <p className="text-slate-400">
                The Merchant shall indemnify, defend, and hold FORMAX and
                entities associated with FORMAX including its directors,
                employees and agents harmless from and against any liabilities,
                claims or demands (including the costs, expenses, dispute
                resolution costs and attorneys’ fees on account thereof)
                relating to or which may result from any:
              </p>
              <ul>
                <p className="text-slate-400">
                  Breach by the Merchant of its obligations, including but not
                  limited to those specified in these Terms and Conditions;
                </p>
                <p className="text-slate-400">
                  Any injuries to persons or damage to the Equipment, including
                  theft, resulting from Merchant's acts or omissions;
                </p>
                <p className="text-slate-400">
                  Any claims brought against FORMAX arising in whole or in part
                  out of claims brought against the Merchant involving, fraud,
                  mishandling or misplacing Transaction Receipt during the term
                </p>
                <p className="text-slate-400">
                  Any claim or proceeding brought by the customer against FORMAX
                  and/or entitles associated with FORMAX in respect of any
                  products of the Merchant;
                </p>
                <p className="text-slate-400">
                  Claim or proceeding brought against FORMAX and/or entitles
                  associated with FORMAX in respect of any services or
                  operations of the Merchant;
                </p>
                <p className="text-slate-400">
                  Any act of negligence or default by the Merchant or the
                  Merchant’s affiliates, agents, employees or licensees or the
                  Merchant;
                </p>
                <p className="text-slate-400">
                  Any act or omission by the Merchant in respect of the sale of
                  /payment for the products;
                </p>
                <p className="text-slate-400">
                  Any fines, penalties, interest on delayed payments imposed
                  directly or indirectly on FORMAX and/or entities associated
                  with FORMAX on account of the Merchant and/or the products.
                </p>
                <p className="text-slate-400">
                  Notwithstanding anything contained in these Terms and
                  Conditions, in no event shall FORMAX and/or entities
                  associated with FORMAX be directly or indirectly liable to the
                  Merchant for any consequential, remote, direct, indirect,
                  special, incidental or punitive loss, damages, compensation,
                  costs, charges or expenses (including without limitation, loss
                  of profits or revenues, loss of opportunity etc.) of any
                  nature whatsoever by reason of termination of the arrangement
                  between the Merchant and FORMAX.
                </p>
                <p className="text-slate-400">
                  The Merchant shall fully indemnify and keep indemnified FORMAX
                  and/or entities associated with FORMAX from all damages,
                  costs, legal fees, charges and expenses, and losses that
                  FORMAX and/or entities associated with FORMAX may incur as a
                  consequence of any failure whether temporary or permanent of
                  the Support Services (as modified from time to time) provided
                  by FORMAX Nodal Banks and or any other entity associated with
                  FORMAX to the extent such failure results in any wrongful or
                  incorrect payment to the Merchant. The Merchant shall also
                  undertake to make good and reimburse FORMAX and/or entities
                  associated with FORMAX and/or the customer for any failure of
                  the Support Services, to the extent of such failure results in
                  any wrongful or incorrect payment to the Merchant.
                </p>
                <p className="text-slate-400">
                  In the event of FORMAX being entitled to be indemnified
                  pursuant to these Terms and Conditions, FORMAX shall be
                  entitled to accordingly and to such extent debit the payments
                  to be made to the Merchant irrespective of any dispute that
                  the Merchant may have in respect of such debit and shall be
                  also entitled to collect the said money in any manner
                  whatsoever.
                </p>
              </ul>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Limitation of Liability :
              </h5>
              <p className="text-slate-400">
                FORMAX, including its officers, directors, shareholders,
                employees, sub-contractors, vendors, suppliers, business
                associates, parent companies, sister companies, subsidiaries and
                other affiliates will not be liable to the maximum extent
                permitted by the applicable law, for any direct, indirect,
                incidental, or consequential damage, or any other damage and
                loss (including loss of profit, loss of data and work stoppage),
                costs, expenses and payments, regardless of the alleged
                liability or form of action, whether in contract, tort or
                otherwise, including negligence, intellectual property
                infringement, product liability and strict liability, that may
                result from, or in connection with the use of terminal or the
                inability to access the terminal and provision of the services,
                or from any failure, error, or downtime in the function of the
                services, or from any fault or error made by FORMAX’s staff, or
                from your reliance on content delivered through the services, or
                from the nature of content delivered through the services, or
                from any communication with FORMAX or from any denial or
                cancellation of request for information through the services, or
                from retention, deletion, disclosure or any other use or loss of
                content through the services, regardless of whether FORMAX has
                been advised of the possibility of such damages. In any event,
                Merchant’s sole remedy will be limited to the corrections of
                such errors, as deemed fit by FORMAX in its sole discretion.
                without prejudice to the aforesaid, it is hereby acknowledged by
                Merchant that the aggregate liability of FORMAX, for any reasons
                whatsoever, will not exceed Rs. 100
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">Confidentiality :</h5>
              <ul>
                <p className="text-slate-400">
                  Each Party (the “Receiving Party”) undertakes to hold in
                  strict confidence the terms of this Agreement and all other
                  non-public information, technology, materials, customer data
                  including Personal Information and Aadhaar Details of the
                  Customers, business information, financial information,
                  software and know-how of the other party (“Disclosing Party”)
                  disclosed to or acquired by the Receiving Party pursuant to or
                  in connection with this Agreement (“Confidential
                  Information”). Neither Party will disclose the Confidential
                  Information of the Disclosing Party to any third party other
                  than the Receiving Party’s employees or legal consultants or
                  Merchant or affiliates on a need-to-know basis who are bound
                  by obligations of non-disclosure and limited use at least as
                  strict as those contained herein. The Receiving Party will not
                  use any Confidential Information for any purpose other than to
                  carry out the activities contemplated under this Agreement.
                  Each Party agrees to use commercially reasonable efforts and
                  have measures in place to protect Confidential Information of
                  the other Party, and in any event, to take precautions no less
                  stringent as those taken to protect its own Confidential
                  Information of a similar nature, which shall always be of at
                  least a reasonable standard.
                </p>
                <p className="text-slate-400">
                  Each Party will also notify the other promptly in writing in
                  the event such Party learns of any unauthorized use or
                  disclosure of any Confidential Information that it has
                  received from the other Party, and will cooperate in good
                  faith to remedy such occurrence to the extent reasonably
                  possible. Upon request of the other Party, each Party will
                  return to the other all materials, in any medium, that contain
                  or reveal all or any part of any Confidential Information of
                  the other Party, or at the Disclosing Party’s option, destroy
                  all copies of any Confidential Information and certify in
                  writing such destruction to the requesting Party. The Parties
                  agrees that any Confidential Information obtained in
                  connection with this Agreement will be collected, used,
                  stored, and maintained according to generally accepted data
                  collection standards and in compliance with all Applicable
                  Laws.
                </p>
                <p className="text-slate-400">
                  Exceptions. The restrictions set forth in this Clause will not
                  apply to any information that: (a) was known by the Receiving
                  Party without obligation of confidentiality prior to
                  disclosure thereof by the Disclosing Party; (b) was in or
                  entered the public domain through no fault of the Receiving
                  Party; (c) is disclosed to the Receiving Party by a third
                  party legally entitled to make such disclosure without
                  violation of any obligation of confidentiality; (d) is
                  required to be disclosed by Applicable Laws or regulations
                  (but in such event, only to the extent required to be
                  disclosed, and provided that the Disclosing Party is given a
                  reasonable opportunity, where legally permissible, to seek a
                  protective order in respect of such Confidential Information);
                  (e) is independently developed by the Receiving Party without
                  reference to any Confidential Information of the Disclosing
                  Party; or (f) is authorized for disclosure to a third party at
                  the direction of the Disclosing Party, provided that the
                  foregoing exception shall only apply to the third party
                  specified by the Disclosing Party for that particular
                  disclosure
                </p>
                <p className="text-slate-400">
                  Any obligations regarding confidentiality shall subsist post
                  the termination or expiry of this Agreement.
                </p>
              </ul>

              <h4>
                <strong></strong>
              </h4>
              <h5 class="text-xl font-semibold mb-4 mt-8">Miscellaneous :</h5>
              <ul>
                <p className="text-slate-400">
                  <b>Assignment</b>: Neither Party (including its
                  Representatives) can assign or otherwise transfer, dispose or
                  part with any of its rights or obligations hereunder to any
                  person without the prior written consent of the other Party.
                  FORMAX may provide such consent subject to the Merchant
                  agreeing to such conditions as may be specified by FORMAX.
                </p>
                <p className="text-slate-400">
                  <b>Force Majeure</b>: Except as provided herein no Party shall
                  be liable to the other for non-performance of its obligations
                  under this Agreement if such failure is caused due to a Force
                  Majeure Event. Force Majeure Event shall mean any event that
                  is beyond the control of a Party and will include an Act of
                  God, war, civil disturbance, prohibitions or enactments of any
                  kind, import or export regulations, exchange control
                  regulations, fire or accident, shipwrecks, major disruption of
                  the Technology Platform, earthquakes, etc. In the case of
                  Force Majeure Event, FORMAX may rescind or at its own
                  discretion suspend performance for up to six months without
                  incurring any liability for any loss or damages thereby
                  occurred. The party affected by Force Majeure Event shall take
                  all necessary steps to mitigate the same.
                </p>
                <p className="text-slate-400">
                  <b>Amendment and Supersession</b>: FORMAX can amend or alter
                  all or any of the terms and conditions of this Agreement and
                  such amended terms and conditions will be binding on Merchant
                  as per intimation in respect thereto. This Agreement together
                  with any instructions or directions or mandates in writing or
                  by way of email or letter sent by FORMAX to Merchant from time
                  to time, constitutes entire Agreement between FORMAX and
                  Merchant and supersedes prior Service Agreement entered into
                  between the Parties. Merchant agrees to be bound by such
                  instructions, directions, or mandates issued by FORMAX from
                  time to time.
                </p>
                <p className="text-slate-400">
                  <b>Governing Law</b>: This Agreement would be governed in
                  accordance with the laws of India. The courts at Kolkata, West
                  Bengal would have exclusive jurisdiction over any and all
                  matters arising under this Agreement.
                </p>
                <p className="text-slate-400">
                  <b>Severability</b>: If any provision of this Agreement is
                  held invalid or unenforceable by any court of final
                  jurisdiction, it is the intent of the Parties that all other
                  provisions of this Agreement shall be construed to remain
                  fully valid, enforceable, and binding on the Parties.
                </p>
                <p className="text-slate-400">
                  <b>Arbitation</b>: In the event of any dispute that may arise
                  between the Parties, the same shall be referred to Arbitration
                  and a sole Arbitrator shall be appointed by FORMAX. The
                  Proceedings of arbitration shall be in accordance with the
                  Indian Arbitration and Conciliation Act, 1996 or any statutory
                  modification thereof. The Venue of Arbitration shall be
                  Kolkata and the arbitration proceedings shall be in English
                  language. The Arbitration Award shall be final and binding on
                  both the parties in the event of any dispute, Courts at
                  Kolkata, West Bengal shall have exclusive jurisdiction under
                  the Laws of India.
                </p>
                <p className="text-slate-400">
                  <b>Notices</b>: All notices, requests, demands, and other
                  communications hereunder shall be in writing and the same
                  shall be deemed to be served if given personally delivered or
                  sent by registered mail details whereof are first mentioned
                  above in the agreement.
                </p>
                <p className="text-slate-400">
                  <b>Waiver</b>: The failure of either Party to require the
                  performance of any of the terms of this Agreement or the
                  waiver by either Party of any breach of this Agreement, will
                  neither prevent a subsequent enforcement of such terms nor be
                  deemed a waiver of any subsequent breach.
                </p>
                <p className="text-slate-400">
                  <b>Relationship Between the Parties</b>: The relationship
                  between FORMAX and Merchant shall be based on specific
                  Service’s and governed by the arrangement/agreement between
                  FORMAX and particular Service Provider for such Services, for
                  example, for any Bank as Service Provider under Business
                  Correspondent arrangement with FORMAX, the relationship
                  between FORMAX and Merchant shall be that of principal and
                  agent. For certain Services like Recharge Services and Ticket
                  Booking Services, the relationship between FORMAX and Merchant
                  shall be on principal-to-principal basis and the Parties shall
                  be independent contractors. Nothing contained in this
                  Agreement constitutes or is to be construed as constituting
                  FORMAX and the Merchant as partner, employee or joint venture
                  of the other, with respect to this Agreement. The Merchant
                  shall not have any right, express or implied, or authority to
                  assume or create any obligation on behalf of or in the name of
                  FORMAX or to bind FORMAX to any contract, agreement or
                  undertaking with any third party unless expressly so
                  authorized by FORMAX.
                </p>
                <p className="text-slate-400">
                  <b>Verification of Information</b>: In order to provide the
                  services per this agreement we have entered into arrangements
                  with various Acquiring Banks, Nodal Bank, Financial
                  Institutions, Card Associations and other software providers
                  who are in the business of providing information technology
                  services and we are obliged to share the information/ data
                  collected during the currency of this agreement and this
                  requirement is fully understood and acknowledged by the
                  Merchant.
                </p>
                <p className="text-slate-400">
                  <b>Non-Solicitation</b>: At any time during the subsistence of
                  this Agreement, Neither Party shall, without the prior written
                  consent of the other Party, either on its own account, through
                  its Representatives canvass or solicit for employment or
                  engagement of, or employ or engage, or entice or make any
                  offer to provide for taking any services to the Representative
                  of the other Party.
                </p>
                <p className="text-slate-400 mb-10">
                  <b>Counterparts</b>: The Agreement may be executed in any
                  number of counterparts, each of which will be deemed an
                  original, but all of which taken together shall constitute one
                  single agreement between the Parties
                </p>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default TermsAndConditions;
