import React from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

const AboutUs = () => {
  return (
    <React.Fragment>
      <NavBar />

      <section class="relative table w-full py-28 lg:py-12 bg-gray-50 dark:bg-slate-800">
        <div class="container">
          <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">About Us</h3>
          </div>
        </div>
      </section>
      <div class="relative">
        <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            class="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section class="relative md:py-24 py-16">
        <div class="container">
          <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-2 gap-[30px]">
            <div class="lg:col-span-5">
              <img src="images/about-us-3.jpg" alt="" />
            </div>
            <div class="lg:col-span-7  md:mt-0">
              <div class="lg:ml-10">
                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Who we are ?
                </h3>

                <div class="flex mb-4">
                  <span class="text-indigo-600 text-2xl font-bold mb-0">
                    <span
                      class="counter-value text-6xl font-bold"
                      data-target="15"
                    >
                      5
                    </span>
                    +
                  </span>
                  <span class="self-end font-medium ml-2">
                    Years <br /> Experience in IT industry
                  </span>
                </div>

                <p
                  class="text-slate-400 max-w-xl"
                  style={{ textAlign: "justify" }}
                >
                  Formax Pay is a brand owned by Formax It Solutions Private
                  Limited (CIN: U72900WB2018PTC228240). It is licensed and
                  authorized by multiple banks to issuance and operation Prepaid
                  Payment Instruments (PPIs) & act as a Corporate Business
                  Correspondent (CBC) as per the guidelines issued by RBI to
                  deliver full-stack financial & banking services.
                </p>

                <p
                  class="text-slate-400 max-w-xl"
                  style={{ textAlign: "justify" }}
                >
                  Since 2017, we are working towards building a robust platform
                  to offer seamless financial services. We at Formax Pay empower
                  shop owners by providing various financial, banking, payment,
                  collection, insurance, tours, travel, and e-governance
                  services through our robust platform.
                </p>

                <div class="mt-6">
                  <a
                    href="contact_us"
                    class="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mr-2 mt-2 btn-sm"
                  >
                    <i class="uil uil-envelope"></i> Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/*         
          <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div class="lg:col-span-5 md:col-span-6">
              <div class="grid grid-cols-12 gap-6 items-center">
                <div class="col-span-6">
                  <div class="grid grid-cols-1 gap-6">
                    <img
                      src="images/about-us-3.jpg"
                      class="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-7 md:col-span-6">
              <div class="lg:ml-5">
                <div class="flex mb-4">
                  <span class="text-indigo-600 text-2xl font-bold mb-0">
                    <span
                      class="counter-value text-6xl font-bold"
                      data-target="15"
                    >
                      5
                    </span>
                    +
                  </span>
                  <span class="self-end font-medium ml-2">
                    Years <br /> Experience in IT industry
                  </span>
                </div>

                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Who we are ?
                </h3>

                <p
                  class="text-slate-400 max-w-xl"
                  style={{ textAlign: "justify" }}
                >
                  Formax Pay is a brand owned by Formax It Solutions Private
                  Limited (CIN: U72900WB2018PTC228240). It is licensed and
                  authorized by multiple banks to issuance and operation Prepaid
                  Payment Instruments (PPIs) & act as a Corporate Business
                  Correspondent (CBC) as per the guidelines issued by RBI to
                  deliver full-stack financial & banking services.
                </p>

                <p
                  class="text-slate-400 max-w-xl"
                  style={{ textAlign: "justify" }}
                >
                  Since 2017, we are working towards building a robust platform
                  to offer seamless financial services. We at Formax Pay empower
                  shop owners by providing various financial, banking, payment,
                  collection, insurance, tours, travel, and e-governance
                  services through our robust platform.
                </p>

                <div class="mt-6">
                  <a
                    href="contact_us"
                    class="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mr-2 mt-2"
                  >
                    <i class="uil uil-envelope"></i> Contact us
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 md:pb-0 pb-0">
        <div class="container">
          <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Our Professional Team
            </h3>
          </div>

          <div class="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
            <div class="lg:col-span-3 md:col-span-6">
              <div class="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900">
                <img
                  src="assets/images/client/01.jpg"
                  class="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800"
                  alt=""
                />

                <div class="content mt-4">
                  <a
                    href="#"
                    class="text-lg font-medium hover:text-indigo-600 block"
                  >
                    Mr. Ezazul Haque
                  </a>
                  <span class="text-slate-400 block">
                    Managing Director, CEO
                  </span>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-6">
              <div class="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900">
                <img
                  src="assets/images/client/01.jpg"
                  class="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800"
                  alt=""
                />

                <div class="content mt-4">
                  <a
                    href="#"
                    class="text-lg font-medium hover:text-indigo-600 block"
                  >
                    Mr. Mijanur Haque
                  </a>
                  <span class="text-slate-400 block">Co-Founder</span>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-6">
              <div class="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900">
                <img
                  src="assets/images/client/01.jpg"
                  class="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800"
                  alt=""
                />

                <div class="content mt-4">
                  <a
                    href="#"
                    class="text-lg font-medium hover:text-indigo-600 block"
                  >
                    Mr. Jasim Ali
                  </a>
                  <span class="text-slate-400 block">Core Founding Team</span>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-6">
              <div class="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900">
                <img
                  src="assets/images/client/01.jpg"
                  class="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800"
                  alt=""
                />

                <div class="content mt-4">
                  <a
                    href="#"
                    class="text-lg font-medium hover:text-indigo-600 block"
                  >
                    Mr. Nazimuddin Mondal
                  </a>
                  <span class="text-slate-400 block">Core Founding Team</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
